<div class="inline text-left relative">
    <div class="inline text-brand space-x-2 cursor-pointer" (click)="toggleVisible()">
        <span class="text-xs">{{ permissionStr }}</span> <span><i *ngIf="!isVisible" class="fa-light fa-chevron-down text-xs"></i><i *ngIf="isVisible" class="fa-light fa-chevron-up text-xs"></i></span>
    </div>
    <div *ngIf="isVisible" class="absolute right-0 top-8 flex flex-col shadow-lg bg-slate-800 text-white z-50 divide-slate-900 divide-y w-[200px] left-[calc(50%_-_100px)]">
        <div class="p-2 group hover:bg-slate-900 cursor-pointer transition-all" (click)="selectPermission(Permissions.Read)">
            <div class="text-sm group-hover:text-brand transition-all">Read Only</div>
            <div class="text-xs text-white/60 group-hover:text-brand transition-all">User can only access and read credentials within the group.</div>
        </div>
        <div class="p-2 group hover:bg-slate-900 cursor-pointer transition-all" (click)="selectPermission(Permissions.Write)">
            <div class="text-sm group-hover:text-brand transition-all">Read & Write</div>
            <div class="text-xs text-white/60 group-hover:text-brand transition-all">User can add/edit credentials within the group.</div>
        </div>
        <div class="p-2 group hover:bg-slate-900 cursor-pointer transition-all" (click)="selectPermission(Permissions.Admin)">
            <div class="text-sm group-hover:text-brand transition-all">Group Admin</div>
            <div class="text-xs text-white/60 group-hover:text-brand transition-all">User can add/edit credentials and users within the group.</div>
        </div>
    </div>
</div>
